<template>
    <div>
        <div class="import-wrap">
            <div>导入方法</div>
            <div>
                <span>1、请 </span>
                <xk-button
                    type="primary"
                    text
                    margin-left="0"
                    @click="downloadTemplate"
                >下载模板
                </xk-button>
                <span> 按照模板填充数据，上传填好的文件</span>
            </div>
            <el-upload
                ref="upload"
                action="#"
                :headers="headers"
                :file-list="fileList"
                accept=".xlsx,.xls"
                :on-change="handleChange"
                :show-file-list="true"
                :auto-upload="false"
            >
                <span>2、</span>
                <xk-button
                    type="primary"
                    text
                    margin-left="0"
                >上传文件
                </xk-button>
            </el-upload>
        </div>
        <div style="text-align: right">
            <xk-button @click="importDialogClose">取消</xk-button>
            <xk-button
                :loading="loading"
                type="primary"
                @click="importDialogSubmit"
            >确定
            </xk-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImportDialog",
    props: {
        loading: {
            type: Boolean
        },
        headers: {
            type: Object
        },
        fileList: {
            type: Array
        }
    },
    methods: {
        handleChange(file) {
            this.$emit('handleChange', file)
        },
        downloadTemplate() {
            this.$emit('downloadTemplate')
        },
        importDialogClose() {
            this.$emit('importDialogClose')
        },
        importDialogSubmit() {
            this.$emit('importDialogSubmit')
        },
    }
}
</script>

<style scoped lang="scss">
.import-wrap {
    margin-bottom: 20px;
}
</style>
